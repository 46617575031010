import { Button, Typography } from '@mui/material';
import useProjectId from 'hooks/useProjectId';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ResetDone: React.FC = () => {
  const navigate = useNavigate();
  const projectId = useProjectId();

  return (
    <>
      <Typography variant="h6" style={{ marginTop: '16px' }}>
        Choose new password
      </Typography>
      <p>Your password has been reset! Please continue to the login page.</p>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        style={{ margin: '16px auto' }}
        onClick={() => navigate(`/${projectId}/login`)}
      >
        Continue
      </Button>
    </>
  );
};

export default ResetDone;
