import React from 'react';
import { useForm } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import useSendReset from '../../../../queries/user/useSendReset';
import TextComponent from '/components/form/Text';

interface Props {
  doneHandler: () => void;
}

interface FieldValues {
  password: string;
  confirmPassword: string;
}

const ResetForm: React.FC<Props> = ({ doneHandler }) => {
  const [searchParams] = useSearchParams();
  const sendReset = useSendReset(doneHandler);
  const { handleSubmit, getValues, control } = useForm<FieldValues>({ mode: 'all' });

  const onSubmit = ({ password }: FieldValues) => {
    const token = searchParams.get('token');
    if (!token) {
      return;
    }
    sendReset.mutate({ password, token });
  };

  return (
    <>
      <Typography variant="h6" style={{ marginTop: '16px' }}>
        Choose new password
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextComponent
          name="password"
          control={control}
          label="Password"
          type="password"
          rules={{
            required: { value: true, message: 'Enter password' },
            minLength: { value: 8, message: 'Password is too short' },
          }}
          helperText="Minimum 8 characters."
        />

        <TextComponent
          name="confirmPassword"
          control={control}
          label="Confirm Password"
          type="password"
          rules={{
            required: { value: true, message: 'Enter confirm password' },
            validate: {
              passwordMatch: (v: string) => (v === getValues('password') ? true : 'Passwords do not match'),
            },
          }}
        />

        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          style={{ margin: '16px auto' }}
          loading={sendReset.isPending}
        >
          Confirm
        </LoadingButton>
      </form>
    </>
  );
};

export default ResetForm;
