import { useMutation } from '@tanstack/react-query';
import * as Backend from 'queries/BackendRequest';

interface MutationProps {
  token: string;
  password: string;
}

const useSendReset = (onDone: () => void) => {
  return useMutation({
    mutationFn: async ({ token, password }: MutationProps) => {
      await Backend.request('post', '/reset', { token }, { password });
    },
    onSuccess: () => {
      onDone();
    },
  });
};

export default useSendReset;
